.notification-dropdown {
  .mark-all-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px !important;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;

    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
  }
  .mark-all-button:hover {
    background-color: #0056b3;
  }
  .notification-tab {
    height: 100%;
  }
}

.notification-dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  height: 300px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
  width: 400px;
  z-index: 200;
  max-height: 300px;
  overflow-y: auto;
}

.notification-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 600px) {
  .inner-header {
    z-index: 0;
  }
  .notification-dropdown {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    max-height: calc(100vh);
    z-index: 10;
    padding: 0;
    height: 100vh;

    .dropdown-header {
      padding: 16px;
      border-bottom: 1px solid #cccccc;
      position: fixed;
      background-color: #fff;
      width: 100vw;
    }
    .dropdown-body {
      padding-top: 79px;
    }
  }
}
