$primaryColor: #2e95d2;
$darkPrimaryColor: #2770d6;
$lightGrey: #707379;
$lightestGrey: #f8f8f8;
$bodyBackground: #eef0f4;
$link: #1c86b8;
$darkBlack: #555555;
$lightBlack: #707779;
$lightBg: #f5f5f5;
$green: #03a00c;
$white: #fff;
$greyDeep: #666666;
$pitchBlack: #000;
$ashGrey: #eaeaea;
