@import "../../styles/colors";
@import "../../styles/mixins";

.notification-card {
  padding: 20px 24px;
  border-bottom: 1px solid #e5e5e5;

  .notification-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14px;

    .c1 {
      .icon {
        border-radius: 50%;
        padding: 10px;
        border: 1px solid #e5e5e5;
      }
    }
    .c2 {
      align-self: flex-start;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 6px;
      .text-title {
        font-size: 16px;
        font-weight: 600;

        margin-bottom: 2px;
      }
      .text-sub-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 13.31px;
        letter-spacing: -0.08461538702249527px;
        text-align: left;
      }
      .text-light {
        font-size: 13px;
        font-weight: 500;
        line-height: 13.31px;
        letter-spacing: -0.08461538702249527px;
        text-align: left;
        color: #999999;
      }
    }
  }
  .read {
    .text-title {
      color: #00000080;
    }
    .text-sub-title {
      color: #00000080;
    }
    .text-light {
      color: rgba(153, 153, 153, 0.5);
    }
  }
  @include small() {
    padding: 20px 24px;
    border-bottom: 1px solid #e5e5e5;

    .notification-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 14px;

      .c1 {
        .icon {
          border-radius: 50%;
          padding: 10px;
          border: 1px solid #e5e5e5;
        }
      }
      .c2 {
        align-self: flex-start;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 6px;
        .text-title {
          font-size: 16px;
          font-weight: 600;

          margin-bottom: 2px;
        }
        .text-sub-title {
          font-size: 14px;
          font-weight: 500;
          line-height: 13.31px;
          letter-spacing: -0.08461538702249527px;
          text-align: left;
        }
        .text-light {
          font-size: 13px;
          font-weight: 500;
          line-height: 13.31px;
          letter-spacing: -0.08461538702249527px;
          text-align: left;
          color: #999999;
        }
      }
    }
    .read {
      .text-title {
        color: #00000080;
      }
      .text-sub-title {
        color: #00000080;
      }
      .text-light {
        color: rgba(153, 153, 153, 0.5);
      }
    }
  }
}
