.side-bar {
  background: #1c86b8;
  color: #aab4ae;
  height: calc(100vh - 44px);
  width: 42px;

  .icon-button {
    width: 42px;
    height: 36px;
    cursor: pointer;
    position: relative;
    .dot {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  .active {
    background: #24638b;
  }
}

@media screen and (max-width: 600px) {
  .side-bar {
    display: none;
  }
}
