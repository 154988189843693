@import "./styles/utils.scss";
@import "./styles/colors";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #333;
}

.body-container {
  // overflow: scroll;
  // max-width: 1362px;
  // max-height: calc(100vh - 88px);
  width: 100%;
  // height: calc(100vh - 88px);
  padding: 18px !important;
}

.btn-save {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100%;
  background-color: $green;
  padding: 0 16px;
  text-transform: uppercase;
  cursor: pointer;
}

.bg-green {
  background: #03a00c;
  border: 1px solid #03a00c;
  padding: 8px 40px;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  border-radius: 0;
  color: #ffffff;
  &:hover {
    background-color: #04be0e;
  }
}
