.popup-status-change {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  .popup-status-change-inner {
    width: 100%;
    position: absolute;
    top: 88px;
    max-width: 240px;
    border-radius: 5px;
    padding: 16px;
    background: #f7f7f7;
    border: 1px solid #1c86b8;
    box-sizing: border-box;
    border-radius: 2px;

    .title {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 24px;
      color: #333333;
    }

    .status-button-wrapper {
      margin-top: 5px;
      text-align: right;
      .btn {
        border-radius: 0;
      }
      .light {
        margin-right: 6px;
      }
    }
  }
}
