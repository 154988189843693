.primary {
  background: #1c86b8;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;
  border-radius: 0;
  color: #ffffff;
  &:hover {
    background: #1c86b8;
  }
}
.primary-custom {
  background: #1c86b8;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;
  border-radius: 0;
  color: #ffffff;
  &:hover {
    background: #1c86b8;
  }
}

.secondary {
  background: #f7f7f7;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  text-align: right;
  text-transform: uppercase;

  color: #1c86b8;
  text-align: center;
  border-radius: 0;
}
