@mixin large() {
  @media only screen and (min-width: 1500px) {
    @content;
  }
}
@mixin small() {
  @media only screen and (max-width: 600px) {
    @content;
  }
}
