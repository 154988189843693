.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 280px;
  z-index: 200;
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
  }
  li {
    padding: 14px 12px;
    cursor: pointer;
  }
  .profile-wrapper {
    // height: 200px;
  }
}
