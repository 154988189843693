.header {
  background: #000000;
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  .user-image {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  .sidedrawer-toggle-button {
    display: none;
    width: 44px;
    height: 44px;
    background: url("../../images/header/sandwich.svg") no-repeat center center;
    cursor: pointer;
  }

  .dropdown-wrapper {
    .dropdown {
      font-size: 14px;
      line-height: 17px;
      color: #fff;
    }
  }

  .logo {
    padding-left: 4px;
    height: 24px;
  }
}

@media screen and (max-width: 600px) {
  .header {
    height: 48px;
    position: fixed;
    top: 0;
    left: 0;
    background: #000000;
    z-index: 5;
    padding: 0 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .sidedrawer-toggle-button {
      display: flex !important;
    }

    .logo {
      height: 20px;
    }
  }
}
