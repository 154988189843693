/* Flex Settings */

.flex {
  display: flex;
}
.flex.row {
  direction: row;
}
.flex.col {
  flex-direction: column;
}

.jcc {
  justify-content: center;
}
.aic {
  align-items: center;
}
.aie {
  align-items: flex-end;
}
.ais {
  align-self: flex-start;
}

.jcsb {
  justify-content: space-between;
}
.jcs {
  justify-content: start;
}
.jce {
  justify-content: flex-end;
}

// Buttons

.btn {
  padding: 5px 8px !important;
  &.light {
    background: #ffffff;
    border: 1px solid #e4e4e4;
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
    color: #1c86b8;
    border-radius: 0;
  }
  &.primary {
    background: #03a00c;
    border: 1px solid #03a00c;
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    border-radius: 0;
    &:hover {
      color: #03a00c;
    }
  }
  &.blue {
    background: #1c86b8;
    border: 1px solid #1c86b8;
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0;
    color: #ffffff;
  }
}

.align {
  &-right {
    text-align: right;
  }
}

.link {
  color: #1c86b8;
  cursor: pointer;
}

.link-w {
  color: #ffffff;
}

.button.link {
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */

  text-align: right;
  display: inline;
  color: #1c86b8;
  &.bold {
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    /* identical to box height, or 218% */

    text-align: right;

    color: #1c86b8;
  }
}

.inner-header-create-button {
  height: 100%;
  padding: 0 16px;
  background-color: #1c86b8;
  border-radius: 0 !important;
  a {
    color: white;
  }
  &:hover {
    background-color: #1f4b66 !important;
  }
}

.label {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 4px;
  color: #707779;
}

.w100 {
  width: 100% !important;
}

.inline {
  display: inline !important;
}

.sub-title {
  font-size: 16px;
}

// Define your spacing scale
$spacing-scale: (
  0: 0px,
  1: 4px,
  2: 8px,
  3: 12px,
  4: 16px,
  5: 20px,
  // Add more values as needed
);

// Create padding classes
@each $key, $value in $spacing-scale {
  .p-#{$key} {
    padding: $value;
  }
}

// Create margin classes
@each $key, $value in $spacing-scale {
  .m-#{$key} {
    margin: $value;
  }
}

// Create padding classes
@each $key, $value in $spacing-scale {
  .pt-#{$key} {
    padding-top: $value;
  }
  .pr-#{$key} {
    padding-right: $value;
  }
  .pb-#{$key} {
    padding-bottom: $value;
  }
  .pl-#{$key} {
    padding-left: $value;
  }
}

// Create margin classes
@each $key, $value in $spacing-scale {
  .mt-#{$key} {
    margin-top: $value;
  }
  .mr-#{$key} {
    margin-right: $value;
  }
  .mb-#{$key} {
    margin-bottom: $value;
  }
  .ml-#{$key} {
    margin-left: $value;
  }
}

.bold {
  font-weight: 500;
}

.section {
  margin-bottom: 24px;
  &-title {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 12px;
    color: #444;
  }
}

.grid-4 {
  display: grid;
  grid-template-columns: 22% 22% 22% 23%;
  gap: 16px;
}
.grid-3 {
  display: grid;
  grid-template-columns: 30% 30% 31%;
  gap: 16px;
}
.grid-2 {
  display: grid;
  grid-template-columns: calc(44% + 16px) 45%;
  gap: 16px;
}
.grid-1 {
  display: grid;
  grid-template-columns: 22%;
  gap: 16px;
}

.MuiPaper-root {
  width: 643px;
  // width: 100%;
  .drawer-container {
    padding: 25px;

    .drawer-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .drawer-header-col-1 {
        display: flex !important;
        align-items: center;
        gap: 20px;
        .back-icon {
          cursor: pointer;
          width: 30px;
          height: 40px;
        }
        .title {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          white-space: nowrap;

          color: #333333;
        }
      }

      .close-icon {
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
    }
    .drawer-body {
      padding-top: 20px;
    }
  }
}

// Flex Utilities
.flex {
  display: flex;
  &.row {
    flex-direction: row;
  }
  &.col {
    flex-direction: column;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.gap-1 {
    gap: 4px !important;
  }
  &.gap-2 {
    gap: 8px !important;
  }
  &.gap-3 {
    gap: 16px !important;
  }
  &.gap-4 {
    gap: 24px !important;
  }
  &.gap-5 {
    gap: 32px !important;
  }
}

.jcc {
  justify-content: center;
}
.jcs {
  justify-content: flex-start;
}
.jce {
  justify-content: flex-end;
}
.jcsb {
  justify-content: space-between;
}
.jcsa {
  justify-content: space-around;
}

.aic {
  align-items: center;
}
.aifs {
  align-items: flex-start;
}
.aie {
  align-items: flex-end;
}
.asfs {
  align-self: flex-start;
}
.asc {
  align-self: center;
}
.ase {
  align-self: flex-end;
}

// Width and Height Utilities
.w-100 {
  width: 100% !important;
}
.w-75 {
  width: 75% !important;
}
.w-50 {
  width: 50% !important;
}
.w-25 {
  width: 25% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}

// Text Utilities
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.font-bold {
  font-weight: bold !important;
}
.font-italic {
  font-style: italic !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.lowercase {
  text-transform: lowercase !important;
}
.capitalize {
  text-transform: capitalize !important;
}

// Border Utilities
.border {
  border: 1px solid #e4e4e4 !important;
  &.top {
    border-top: 1px solid #e4e4e4 !important;
  }
  &.right {
    border-right: 1px solid #e4e4e4 !important;
  }
  &.bottom {
    border-bottom: 1px solid #e4e4e4 !important;
  }
  &.left {
    border-left: 1px solid #e4e4e4 !important;
  }
  &.none {
    border: none !important;
  }
}
.rounded {
  border-radius: 4px !important;
  &.full {
    border-radius: 50% !important;
  }
}

// Color Utilities
.text-primary {
  color: #1c86b8 !important;
}
.text-secondary {
  color: #555555 !important;
}
.text-success {
  color: #03a00c !important;
}
.text-danger {
  color: #dc3545 !important;
}
.bg-primary {
  background-color: #1c86b8 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
.bg-transparent {
  background-color: transparent !important;
}

// Display Utilities
.d-block {
  display: block !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-none {
  display: none !important;
}

// Responsive Utilities
@media (min-width: 576px) {
  .sm:hidden {
    display: none !important;
  }
  .sm:block {
    display: block !important;
  }
}
@media (min-width: 768px) {
  .md:hidden {
    display: none !important;
  }
  .md:block {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .lg:hidden {
    display: none !important;
  }
  .lg:block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .xl:hidden {
    display: none !important;
  }
  .xl:block {
    display: block !important;
  }
}

@media screen and (max-width: 600px) {
  .grid-4 {
    display: grid;
    grid-template-columns: 100% !important;
  }
  .options-wrapper {
    flex-wrap: wrap;
    gap: 16px;
  }
}

// Rounded Corner Utilities
// Rounded Corner Utilities (Tailwind Naming)
.rounded-none {
  border-radius: 0 !important;
}
.rounded-sm {
  border-radius: 0.125rem !important; // 2px
}
.rounded {
  border-radius: 0.25rem !important; // 4px
}
.rounded-md {
  border-radius: 0.375rem !important; // 6px
}
.rounded-lg {
  border-radius: 0.5rem !important; // 8px
}
.rounded-xl {
  border-radius: 0.75rem !important; // 12px
}
.rounded-2xl {
  border-radius: 1rem !important; // 16px
}
.rounded-3xl {
  border-radius: 1.5rem !important; // 24px
}
.rounded-full {
  border-radius: 9999px !important; // Fully rounded for circles/pills
}

// Specific Corner Utilities
.rounded-t-none {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.rounded-t-sm {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}
.rounded-t {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-t-md {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}
.rounded-t-lg {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.rounded-r-none {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.rounded-r-sm {
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}
.rounded-r {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-r-md {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}
.rounded-r-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-b-none {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.rounded-b-sm {
  border-bottom-left-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}
.rounded-b {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-b-md {
  border-bottom-left-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}
.rounded-b-lg {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-l-none {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.rounded-l-sm {
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}
.rounded-l {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-l-md {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}
.rounded-l-lg {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

// Define breakpoints
$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@mixin respond-to($breakpoint) {
  @media (min-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

.trim-create-edit-form {
  &-header,
  &-body {
  }

  .section {
    width: 100%;

    margin-bottom: 24px;

    .grid-container {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

      // Fullscreen layout adjustments based on screen size
      &.fullscreen {
        @include respond-to(md) {
          grid-template-columns: repeat(
            2,
            1fr
          ); // Two columns on medium screens
        }

        @include respond-to(lg) {
          grid-template-columns: repeat(
            2,
            1fr
          ); // Three columns on large screens
        }

        @include respond-to(xl) {
          grid-template-columns: repeat(
            4,
            1fr
          ); // Four columns on extra-large screens
        }
      }
    }

    // Restrict width of certain fields (like Status) in component view
    .restrict-width {
      max-width: 50%;
      padding-right: 8px;
    }
    .isolate {
      padding-left: 16px;
    }
  }

  .field-row {
    display: flex;
    flex-direction: column;

    @include respond-to(md) {
      flex-direction: row;
    }

    .field {
      flex: 1;
      margin: 8px;
      width: 100%;
    }
  }
}
