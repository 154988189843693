.dropdown-menu {
  .arrow {
    padding: 0px 24px 0px 8px;
  }
  .dropdown {
    color: white;
  }
  .profile-image {
    width: 24px;
    border-radius: 50%;
    margin-left: 10px;
  }
}
